<template>
  <div class="create width100">
    <v-card elevation="0" max-width="500" rounded="lg" class="pa-12 width100" v-if="card == 'create'">
      <div class="primary--text text--darken-2 mb-10 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <div class="f28 font-weight-bold mb-1">Create new password</div>
      <div class="text--text mb-7">Password should be at least 8 characters long and contains symbols and digits.</div>
      <v-form @submit.prevent="reset" ref="form" lazy-validation>
        <v-text-field
          class="field48"
          v-model="password"
          placeholder="New password"
          :error-messages="passwordErrors"
          :type="show ? 'text' : 'password'"
          outlined
          dense
          required
          color="primary"
          height="48"
        >
          <template v-slot:append>
            <img class="link" @click.stop="show = !show" :src="require(`@/assets/icon/${show ? 'show' : 'hide'}.svg`)" alt="icon" />
          </template>
        </v-text-field>
        <div class="text-center">
          <v-btn depressed height="48" type="submit" block large color="primary">
            <span class="f15 font-weight-bold">Update</span>
          </v-btn>
        </div>
      </v-form>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'expired'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="d-block mx-auto" src="@/assets/icon/lock-expired.svg" alt="expired" />
      <div class="f28 font-weight-bold mt-5 text-center">Password reset request expired</div>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'already'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="d-block mx-auto" src="@/assets/icon/lock-cancel.svg" alt="already" />
      <div class="f28 font-weight-bold mt-5 text-center">Password request is already used</div>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'fail'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="d-block mx-auto" src="@/assets/icon/lock-fail.svg" alt="fail" />
      <div class="f28 font-weight-bold mt-5 text-center">Password update failed</div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      card: 'create',
      password: '',
      show: false,
      error: [],
    };
  },
  methods: {
    back() {
      this.$router.push('/sign-in');
    },
    async reset() {
      this.error = [];
      const data = new Object();
      data.password = this.password;
      data.token = this.$route.query.t;
      await this.$store
        .dispatch('reset', data)
        .then((res) => {
          if (res.data.auth_token) {
            sessionStorage.setItem('jwt_token', res.data.auth_token);
            this.$route.query.from ? this.$router.push(`/sign-in/otp?from=${this.$route.query.from}`) : this.$router.push('/sign-in/otp');
          } else if (res.data.access_token) {
            sessionStorage.setItem('jwt_token', res.data.access_token);
            this.$router.push(this.$route.query.from || '/');
          } else {
            this.$router.push('/sign-in');
          }
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((i) => i == 'token__expired')) {
            this.card = 'expired';
          } else if (this.error.find((i) => i == 'token__already_used')) {
            this.card = 'already';
          } else {
            this.card = 'fail';
          }
        });
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>
